import './_gallery.scss'
import Swiper from "swiper";
import {Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';

const gallery = function () {
    const blocks = document.querySelectorAll('section.gallery');
    // if (window.innerWidth <= 991) {
        document.body.addEventListener('click', () => {
            document.querySelectorAll('video').forEach(vid => {
                if (vid.playing) {
                } else {
                    vid.play();
                }
            });
        }, true);
    // }
    for (const block of blocks) {
        const swiperObject = block.querySelector('.gallery-swiper');
        const gallerySwiper = new Swiper(swiperObject, {
            modules: [Navigation],
            speed: 400,
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: '.custom-button-next',
                prevEl: '.custom-button-prev',
            },
            on: {
                slideChange: function () {
                    const index_currentSlide = this.realIndex;
                    const currentSlide = this.slides[index_currentSlide];
                    block.querySelectorAll('video').forEach(vid => {
                        vid.pause();
                    });
                    if (currentSlide.querySelector('video') != null) {
                        currentSlide.querySelector('video').play();
                    }
                },
            },
        });
    }
};

document.addEventListener('DOMContentLoaded', function () {
    gallery()
}, false);

window.addEventListener('acf/loaded/gallery', function (e) {
    gallery()
});